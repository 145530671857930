import * as React from 'react';
import { useState, useEffect,useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Container,Grid,Typography,Button, IconButton} from "@material-ui/core";
import PageBanner from "./components/pageBanner";
import Panel from "./components/whitePanel";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import httpRequest from "../HttpService";
import {getStatusString, getLocationString} from "../newOrder/components/helperFunctions/index.js";
import MapboxPanel from "./components/MapboxPanel/index";
import logo from "../directitgroupcourier.jpg";
import moment from "moment";
import Carousel from "./components/carouselPanel";
import PrintIcon from "@material-ui/icons/Print";
import CopyIcon from "@material-ui/icons/FileCopy";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import ImageViewer from './components/imageViewer.js';
// import FileUploadIcon from '@mui/icons-material/FileUpload';
// import {Dialog,DialogTitle,DialogContent,DialogActions,} from "@material-ui/core";

import { saveAs } from "file-saver";
import copy from "copy-to-clipboard";
import Copyright from "../Copyright";
import { JSPrintManager, WSStatus, ClientPrintJob,InstalledPrinter,ClientScanJob, ScannerImageFormatOutput } from 'jsprintmanager';
// import ReactToPrint from "react-to-print";
import PrintPanel from "../newOrder/components/printPanel";
import "./orderDetail.css";
import Gallery from "../newOrder/components/gallery";
import ReactPanZoom from "../newOrder/components/ImagePan";
import OrderReceiptPanel from './components/OrderReceiptPanel.js';
import Alert from './components/Alert.js';
//  http://localhost:3001/trackNo/TR7991015074139


export default function TrackingOrderDetail(props) {    
    const { trackingNumber } = useParams();
    const { trackingAccount } = useParams();
    const { trackingReference } = useParams();
    const componentRef = useRef();
    const receiptPrintRef = useRef();

    const [photoArray, setPhotoArray] = useState([]);
    const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [order, setOrder] = useState({});
    const [openAddFilesBox, setOpenAddFilesBox] = useState(false);
    const [selectPrinter, setSelectPrinter] = useState(null);
    const [printsList, setPrintsList] = useState([]);
    const [isPrint, setIsPrint] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [isUploadFile, setIsUploadFile] = useState(false);
    const [isPrintReceipt, setIsPrintReceipt] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    try{
        let search =props.trakingType === 'trackingNumber'?
            {trackingNumber : trackingNumber}
            :
            {
                cust_ref : trackingReference,
                trackAccount : trackingAccount
            };     
            
        // httpRequest.orderSearch("",search).then((body) =>{
        //     console.log("trackingNumber-" +JSON.stringify(body.data)); 
        //     if(body.data.length>0){
        //         let order = body.data[0];
        //         initOrderDetail( order.ACCOUNT,order.jobID);              
        //     }
        //     else
        //         window.location.href='/tracking';
        // });
        
        initTripDetail();

        try{
            JSPrintManager.auto_reconnect = true;
            JSPrintManager.start();
            JSPrintManager.WS.onStatusChanged = () => {
                if (JSPrintManager.websocket_status === WSStatus.Open) {
                        JSPrintManager.getPrintersInfo().then(async (printersList) => {  
                          let printers =  printersList.map((item,i) => {
                          return {value: i, label: item.name};
                        });
                        setPrintsList(printers);                  
                        console.log("tracking Printer list :" + JSON.stringify(printers));
                        var defaultPrinter = printers.find(function (obj) {
                          return obj.label.includes("ZPL");
                        });
                        console.log("Default Printer :" + JSON.stringify(defaultPrinter));
                        if(defaultPrinter !== undefined){
                          var index = printers.findIndex(x => x.label ===defaultPrinter.label); 
                          setSelectPrinter(printers[index].label);                    
                        }          
                        console.log("Tracking selectPrinter :" + selectPrinter);
                    });
                }
            }
  
        }
        catch (e) {
            console.log("JSPrintManager--- exception", e);
        }
      }
      catch (e) {
        console.log("exception", e);
      }
      return () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
      };
  }, []);

  
  const initTripDetail = () =>{
    httpRequest.getTripInfo(trackingNumber).then((response) => {
        console.log("getOrder--" + JSON.stringify(response.data));
        let _order = response.data[0];
        if (_order.dtDelivered)
           _order.dtDelivered_display = _order.dtDelivered.substring(0, 19).replace("T", " ");
        if (_order.dtCallIn)
           _order.dtCallIn_display = _order.dtCallIn.substring(0, 19).replace("T", " ");
        if (_order.dtDCT)
           _order.dtDCT_display = _order.dtDCT.substring(0, 19).replace("T", " ");
        setOrder(_order);           
        getOrderTrack(_order.jobID);
        getPhotos(_order.jobID);   
        if (_order.Status < 8) {
          const newIntervalId = setInterval(() => {
            getPhotos(_order.jobID);          
            httpRequest.getTripInfo(trackingNumber).then((response) => {
              var _order = response.data[0];
              if (_order.dtDelivered)
                _order.dtDelivered_display = _order.dtDelivered.substring(0, 19).replace("T", " ");
              if (_order.dtCallIn)
                _order.dtCallIn_display = _order.dtCallIn.substring(0, 19).replace("T", " ");
              if (_order.dtDCT)
                _order.dtDCT_display = _order.dtDCT.substring(0, 19).replace("T", " ");
              setOrder(_order);
              getOrderTrack(_order.jobID);

              if (_order.Status >= 8) clearInterval(intervalId);
            });
          }, 60000);
          setIntervalId(newIntervalId);       
        }
    });
  }


 
  const setUploadFileFlag = () => {
    setIsUploadFile(true);   
  };

  const getPhotos = (orderID) => {
    //{"url":"https://directitsqlblob.blob.core.windows.net/images/136206101659378995561.png","gpsPoint":null,"comment":"test","drNum":null,"savedTimeUTC":"2022-08-01T18:36:35.763Z","imgID":"7523022"}]
    httpRequest.getPhotoURLs(orderID).then((response) => {
      console.log("getPhotoURLs--" + JSON.stringify(response.data));
      let imageList =  response.data.map((value,i) => {
        value.savedTimeUTC = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
        value.id = i;
        value.imageType =  value.url.endsWith("png") || value.url.endsWith("jpg") || value.url.endsWith("bmp")? "image": "file";
        value.fileType = value.url.endsWith("png") || value.url.endsWith("jpg") || value.url.endsWith("bmp") ? "image" : value.url.substring(value.url.length - 3);       
        value.fileExt = value.url.substring(value.url.length - 3);    
        if (value.fileExt === "pdf" || value.fileExt === "doc" ||value.fileExt === "docx" ||value.fileExt === "xls" ||value.fileExt === "xlsx" ||value.fileExt === "csv"){
          var file_url = `https://drive.google.com/viewerng/viewer?embedded=true&url=${value.url}#toolbar=0&scrollbar=0`;
          value.file_url = file_url;
        }    
        return value;
      });
      setPhotoArray(imageList);
      setIsUploadFile(false);      
    });
  };
  
  const getOrderTrack = (orderID) => {
    httpRequest.getOrderTrack(orderID).then((response) => {
      //console.log("getOrderTrack--" + JSON.stringify(response.data));
      var tempTrackList = response.data.map((option,index) => {
        return{
          ...option,
          id: index
        };
    });
    setSteps(tempTrackList);   
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderStepContentText =(label) =>{  
    return (
      <div>
        <Typography>{label.bigText}</Typography>
        <Typography variant="caption">
          {label.smallText + moment(label.timestamp_utc).format("MM-DD") + " @ " + moment(label.timestamp_utc).format("LT")}        
        </Typography>
        {label.img_url && (
          <span>
            <a onClick={() => { window.open(label.img_http_link, "_blank");}}>
              {label.img_text}
            </a>
            <div>
              <img
                style={{ width: "80px", height: "60px" }}
                src={label.img_url}
                onClick={() => {
                  window.open(label.img_http_link, "_blank");
                }}
              />
            </div>
          </span>
        )}
      </div>
    );
  }

  const renderFromAddress = ()  =>{
    let suite = order.sSuite? order.sSuite + ", " : '';
    return (
      `${suite} ${order.sAddress}  ${order.sQuad}, ${order.sCity}, ${order.sProv}, ${order.sPostal}, ${order.sCountry}`
    );
}

const renderToAddress =() => {
    let suite = order.cSuite? order.cSuite + ", ": ''
    return (
      `${suite} ${order.cAddress}  ${order.cQuad}, ${order.cCity}, ${order.cProv}, ${order.cPostal}, ${order.cCountry}`
    );
}

const handleClick = () => {
    setShowDeliveryDetails(!showDeliveryDetails);    
};

const renderDeliveryDetails = () =>{
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <h6>LOCATION</h6>
          <p>
            {getLocationString(order.location)}
          </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>BILLING REFERENCE #</h6>
          <p> {order.CUST_REF} </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>CALLER</h6>
          <p> {order.CALLER} </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>CALL IN</h6>
          <p>
            {" "}
            {order.dtCallIn_display + " " + order.timezone}
          </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>DUE BY</h6>
          <p> {order.dtDCT_display + " " + order.timezone} </p>
        </Grid>
        <Grid item md={6}>
          <h6>Status</h6>
          <p> {getStatusString(order.Status)} </p>
        </Grid>     
        <Grid item xs={12} sm={6}>
          <h6>PROOF OF DELIVERY</h6>
          <p> {order.PROOF_DEL} </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>ACCESSORIES</h6>
          <p> {order.Accessories} </p>
        </Grid>   
      </Grid>
    );
}

// const printClick = () => {
//     console.log("selectPrinter :" + selectPrinter);
//     if (selectPrinter && selectPrinter.includes("ZPL")) {
//       if (JSPrintManager.websocket_status === WSStatus.Open) {
//         httpRequest.getLabelString(order.jobID).then(async (body) => {
//           console.log("getZPLLabel return-" + JSON.stringify(body.data));  
//           var zplLabel = body.data;
//           if(zplLabel.ZPLString !== null){
//             var cpj = new ClientPrintJob();
//             cpj.clientPrinter = new InstalledPrinter(selectPrinter);
//             cpj.printerCommands = zplLabel.ZPLString;
//             cpj.sendToClient();
//           }
//           else {
//             setOpenSnack(true);
//             setAlertMsg("ZPL string is null!");
//             setIsPrint(true);
//           }
//         });
//       }
//     } else {
//         setIsPrint(true);    
//     }
// };

const doPrintReceipt = useReactToPrint({
  content: () => receiptPrintRef.current,
  onAfterPrint: () => {setIsPrintReceipt(false)},
});


// const cancelPrint = () => { setIsPrint(false); }; 
// const addFilesClick = () => { setOpenAddFilesBox(true) };
// const addFilesClose = () => { setOpenAddFilesBox(false) };
 
//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     this.setState({ [name]: value });
//   };

  return (
    <Container maxWidth="lg" class="NewOrder">
        <div id="bannerBackground">&nbsp;</div>
        <PageBanner title="PARCEL STATUS" subheading={"#" + order.jobID} />
        {/* <div style={{ margin: "15% 8% 5% 8%" }}> */}
        <div id="pageContent">
          {!isPrint && (
            <Grid container>
              <Grid item xs={12}>
                <Panel>
                  <Grid container>
                    <Grid item xs={12} lg={4} xl={2} style={{display:'flex', alignItems:'stretch', justifyContent:'stretch'}}>
                      <Grid container className="border-mobile-bottom">
                        <Grid item xs={6} lg={12} style={{display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center'}}>
                          <h6>Status</h6>
                          <h3>{getStatusString(order.Status)}</h3>
                        </Grid>
                        <Grid item xs={6} lg={12} className="text-mobile-end" style={{display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center'}}>
                          <h6>Tracking Number</h6>
                          {order.TrackNumber && (
                          <span>
                            <strong>{order.TrackNumber}</strong>{" "}
                            <IconButton aria-label="copy" onClick={() =>{copy("https://tracking.directx.ca/trackNo/" + order.TrackNumber);}}>
                              <CopyIcon color="primary" />
                            </IconButton>
                          </span>
                          )}                         
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8} xl={10}>
                      <Stepper  activeStep={activeStep}  alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label.bigText} completed={label.filled}>
                            <StepLabel>
                              {renderStepContentText(label)}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>
              <Grid item xs={12}>
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="d-sm-flex">
                        <div className="pe-sm-4">
                          <img  src={order.brandLogoURL} 
                                style={{ width: "140px", display:"block", marginBottom: "20px" }}
                          />
                          <Grid className="buttonsGrid"  container  direction="column"  spacing={1}>
                            {/* <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  margin: "0",
                                  width: "100%",
                                  minWidth: "200px",
                                }}                               
                                startIcon={<PrintIcon className="side_icon" />}
                                onClick={printClick}
                            >
                                RePrint Label
                              </Button>
                            </Grid>  */}
                            <Grid item>
                              <Button variant="contained" color="primary" style={{margin: "0px", width: "100%",minWidth: "200px"}}
                                  startIcon={<PrintIcon className="side_icon" />} onClick={()=>{setIsPrintReceipt(true)}}
                                >
                                  Print Receipt
                              </Button>                              
                            </Grid>
                          </Grid>
                        </div>
                        <div className="flex-fill">
                          <h6 style={{ paddingTop: '20px' }}>FROM</h6>
                          <p style={{ fontWeight: 600 }}>{order.sCompanyName}</p>
                          <p>{renderFromAddress()}</p>
                          <h6 style={{ paddingTop: '20px' }}>TO</h6>
                          <p style={{ fontWeight: 600 }}>{order.cCompanyName}</p>
                          <p>{renderToAddress()}</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} style={{paddingTop:"20px"}}>
                        <Grid item xs={3} md={3}>
                          <h6>Pieces</h6>
                          <p>{order.PIECES}</p>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <h6>Weight</h6>
                          <p>{order.WEIGHT}</p>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <h6>Description</h6>
                          <p>{order.DESC}</p>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <h6>Service Type</h6>
                          <p>{order.serviceText}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6>Instructions</h6>
                          <p>{order.DEL_INST}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6>Signature</h6>
                          {order.signatureURL && (
                            <img
                              src={order.signatureURL}
                              alt="Customer Signature"
                              style={{ display:"block", width:"100%", maxWidth:"320px" }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <List>
                            <ListItem onClick={handleClick} style={{ padding: 0 }}>
                              <ListItemText
                                disableTypography
                                primary={
                                  <h6 style={{margin:"0"}}>
                                    Delivery Details
                                  </h6>
                                }
                              />
                              {showDeliveryDetails ? (<ExpandLess style={{ color: "#337AB7" }} />) : (<ExpandMore style={{ color: "#337AB7" }} />)}
                            </ListItem>
                            <Collapse
                              in={showDeliveryDetails}
                              timeout="auto"
                              unmountOnExit
                            >
                              {renderDeliveryDetails()}
                            </Collapse>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>
              <Grid item md={7} xs={12}>
                <Panel>
                  <h6>LIVE TRACKING</h6>
                  <Grid container>             
                    {order.jobID && (
                      <MapboxPanel
                        orderID={order.jobID}
                        order={order}
                        photoList={photoArray}
                      />
                    )}
                  </Grid>
                </Panel>
              </Grid>
              <Grid item md={5} xs={12}>
                <Panel>
                  <Grid container>
                    <Grid item xs={6}><h6>IMAGES</h6></Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>                  
                  <Carousel
                    show={photoArray.length !== 0 ? true : false} numOfImages={photoArray.length}
                  >
                    {photoArray.map((image, i) => (                     
                      <div
                          key={i}
                          style={{
                            justifyContent: "center",
                            height: "50%",
                            verticalAlign: "middle",
                            display: "flex",
                          }}
                        >
                          {image.imageType === "image" ?
                            // <ReactPanZoom src={image.url} />
                            <ImageViewer src={image.url} />
                            :
                            <IconButton
                              color="primary"
                              onClick={() => {
                                console.log("onClick");
                                saveAs(image.url, `${order.jobID}-${i}`); // Put your image url here.                                
                              }}
                          >
                            <DownloadIcon />
                            <iframe src={image.file_url} height="420px"  width="100%"></iframe>
                          </IconButton>}
                      </div>                                       
                    ))}
                  </Carousel>
                </Panel>
              </Grid>              
            </Grid>
          )}
            {/* {isPrint && (
              <Panel>
                <Button variant="contained"  color="primary"   onClick={() => {setIsPrint(false)}} style={{ float: "right", margin: "2px" }}>
                  Cancel
                </Button> 
                <Button variant="contained"  color="primary"  onClick={handlePrint}> Print</Button>
                <PrintPanel id={order.jobID} account = {order.ACCOUNT} ref={componentRef}/>
              </Panel>
            )}   */}
             {isPrintReceipt &&<Panel>
              <Button variant="contained"  color="primary"   onClick={() => {setIsPrintReceipt(false)}} style={{ float: "right", margin: "2px" }}>
                  Back
              </Button>
              <OrderReceiptPanel orderID={order.jobID}  order={order} ref={receiptPrintRef} doPrintReceipt={doPrintReceipt}></OrderReceiptPanel>
            </Panel>}     
        </div>      
        {/* <Dialog fullWidth={true} maxWidth="xl" className="addFilesDialog" open={openAddFilesBox} onClose={addFilesClose}>
          <DialogTitle>Add Files</DialogTitle>
          <DialogContent>
              <Gallery  jobId={order.jobID}   setUploadFileFlag={setUploadFileFlag}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={addFilesClose}>Cancel</Button>
          </DialogActions>
        </Dialog>  */}
        <Alert openSnack={openSnack} alertMsg={alertMsg} snackClose={()=>{setOpenSnack(false)}} />    
      <footer>   <Copyright /> </footer>   
    </Container>
  );
}

