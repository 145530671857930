import "./App.css";

import React, { useEffect } from "react";

import Tracking from "./tracking";
import TrackingOrderDetail from "./orderStatus/trackingOrderDetail";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import NavigationBar from "./NavigationBar";



function App() {
  require("dotenv").config();
  
  useEffect(() => {  
     
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Router>          
          <NavigationBar/>
          <Routes>
            <Route path="/"  element={ <Tracking type="none"/>}> </Route> 
            <Route path="/Tracking"  element={ <Tracking type="none"/>}> </Route> 
            <Route path="/trackNo/:trackingNumber" element={<TrackingOrderDetail trakingType="trackingNumber"/>} />
            <Route exact path="/TrackAccount/:trackingAccount/:trackingReference"
                elelment={<TrackingOrderDetail trakingType="reference"/>} />         
          </Routes>         
        </Router>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;

