import axios from "axios";

//const base_url = "http://192.168.2.201:6655/";  //prod
//const base_url = "http://192.168.2.201:6622/"; //test

// const base_url = "http://192.168.2.16:6688/";  //prod
//const base_url = "http://192.168.2.16:6688/";  //prod

const base_url_webx = "https://lattice-webx.azurewebsites.net/"; //dev
//const base_url = window.location.origin+'/'; //staging


console.log(`process.env.NODE_ENV: ${process.env.NODE_ENV}, process.env.REACT_APP_API_URL: ${process.env.REACT_APP_API_URL}`);
let base_url = `${window.location.origin}/`;
if (typeof process !== 'undefined' && process.env && process.env.NODE_ENV === "development") {
	base_url = process.env.REACT_APP_API_URL;
}


const requireAuth = axios.create();

const getOption = () =>{
  return {
		headers: {
			'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '-1',
      'token': localStorage.getItem("sso_token")
		}
  }	
}

class HttpRequest {

  getAPIVersion = () => {
    return requireAuth.get(base_url+ "version", getOption());
  };

/**
   * get tripInfo
   * @param trackingNumber
   **/
getTripInfo = (trackingNumber) => {
  return requireAuth.get(`${base_url}tripInfo/${trackingNumber}`, getOption());
};



  /** get Label String from DB
   * @param accountNumber, jobID
   */
  getLabelString = (id) => {
    return requireAuth.get(`${base_url_webx}labelString/${id}`, getOption());
  };

  getZPLLabel = (accountNumber, id, currentPage, totalPages) => {
    return requireAuth.get(
      `${base_url_webx}ZPLlabel/${accountNumber}/${id}/${currentPage}/${totalPages}`, getOption()
    );
  };

  getOrderTrack = (orderID) => {
    return requireAuth.get(`${base_url_webx}track/${orderID}`, getOption());
  };

  /**
   * get accounts in group
   * @param groupID
   **/
  getGroupAccounts = (groupID) => {
    return requireAuth.get(`${base_url_webx}groupAccounts/${groupID}`, getOption());
  };

  orderSearch = (accountNumber, searchParams) => {
    var content = {
      accountNumber: accountNumber,
      searchParams: searchParams,
    };
    return axios.post(base_url_webx + "searchOrders", content);
  };

  /**
   * get orders
   * @param accountNumber
   **/
  getOrders = (accountNumber) => {
    return requireAuth.get(`${base_url_webx}orders/${accountNumber}`, getOption());
  };

  /**
   * get order
   * @param accountNumber
   * @param orderID
   **/
  getOrder = (accountNumber, orderID) => {
    return requireAuth.get(`${base_url_webx}order/${accountNumber}/${orderID}`, getOption());
  };

  /**
   * get Job GPS
   * @param orderID
   **/
  getJobGPS = (orderID) => {
    return requireAuth.get(`${base_url_webx}jobGPS/${orderID}`, getOption());
  };


  /**
   * get signature photo
   * @returns {Promise}
   * in order status page
   */
  getSignature = (accountNumber, jobID) => {
    return requireAuth.get(
      `${base_url_webx}orderSignature/${accountNumber}/${jobID}`, getOption()
    );
  };

  /**
   * get list of pictures of the order
   * @returns {Promise}
   * in order status page
   */
  getPhotoURLs = (orderID) => {
    return requireAuth.get(`${base_url_webx}photoURL/${orderID}`, getOption());
  };



  /**
   * doUploadImage
   * @param params
   **/
  doUploadImage = (base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return requireAuth.post(`${base_url_webx}image`, content, getOption());
  };

  /**
   * doUploadPDF
   * @param params
   */
  doUploadFile = (base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return requireAuth.post(`${base_url_webx}file`, content, getOption());
  };

  /**
   * add attachment
   * @param params
   */
  doAttachment = (jobID, URL, fileType, comment) => {
    var content = {
      URL: URL,
      fileType: fileType,
      comment: comment,
    };
    return requireAuth.post(`${base_url_webx}attachment/${jobID}`, content, getOption());
  };


}

const httpRequest = new HttpRequest();

export default httpRequest;
