import React,{ useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Box} from '@material-ui/core';
import TextInput from '../newOrder/components/inputFields/TextInput';
import httpRequest from '../HttpService';
import 'animate.css/animate.css';
import {Alert, AlertTitle} from '@material-ui/lab'
import Copyright from '../Copyright';
import './tracking.css';
import { useNavigate } from 'react-router-dom';

export default function Tracking(props)  {   
    const navigate = useNavigate();
    const [state, setState] = useState(
        {
            type:props.type,
            trackAccount:"",
            trackReference:"",
            trackNumber:"",
            successinfo:"none"
        }
    )
    

    const doTracking = (event) => {
        console.log("do Tracking-", state);  
        
        let search={};
        if(state.trackNumber!==""){
           search ={trackingNumber : state.trackNumber};
        }
        else{
            search ={
                cust_ref : state.trackReference,
                trackAccount : state.trackAccount
            };
        }
        httpRequest.orderSearch("",search).then((body) =>{
             console.log(JSON.stringify(body.data));
             console.log("data lenth is " + body.data.length);
            //this.setState({successinfo:"block"});
            if(body.data.length>0){
                navigate(state.trackNumber!==""?`/TrackNO/${state.trackNumber}` : `/TrackAccount/${state.trackAccount}/${state.trackReference}`);                                 
            }
            else
               setState({successinfo:"block"});
       });
    }    

    
    
    const handleChange = (event) => {
        //console.log(event.target.name, event.target.value);
        const { name, value } = event.target;
        setState({...state,[name]: value});
    };

    return (
        <Container  class="tracking">
            <div class={"tracking-wrapper"}>
            <div className={"tracking-wrapper-inner"}>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
                <Grid item md={12}>
                   <h2 class={"text-primary text-uppercase title-small"}> Track Your Order </h2>
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Account"
                      type="text"
                      inputType="text"
                      name="trackAccount"                      
                      labelSize = {5}    
                      value={state.trackAccount}     
                      onChange={handleChange}           
                    />
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Reference"
                      type="text"
                      inputType="text"
                      name="trackReference"
                      labelSize = {5} 
                      value={state.trackReference}  
                      onChange={handleChange}                        
                    />
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Number"
                      type="text"
                      inputType="text"
                      name="trackNumber"
                      labelSize = {5} 
                      value={state.trackNumber} 
                      onChange={handleChange}                         
                    />
                </Grid>   
                <Grid item md={12} style={{width:"120px", textAlign:"center"}}>
                    <Button
                        style={{width:"100%", display:'flex', justifyContent:'space-between',marginTop:'20px'}}
                        variant="contained"                           
                        color="primary" 
                        startIcon={<SearchIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}
                        onClick={doTracking}
                         disabled={(state.trackNumber === "") && ((state.trackReference==="") || (state.trackAccount==="")) }
                    >
                        Track
                    </Button>
                </Grid> 
                <Grid item md={12} style={{marginTop:"80px"}}>
                    <Box display={state.successinfo}>
                    <Alert severity="warning">
                    <AlertTitle>Search Results 0.</AlertTitle>
                    <strong></strong>                   
                   </Alert>
                   </Box>
                </Grid>                 
            </Grid> 
            </div>
            </div>
            {/* Footer */}
            <footer  style={{float:"center"}}>
                <Copyright/>
            </footer>
            {/* End footer */}
        </Container>
    );
}